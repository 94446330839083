import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Button, IconTrash, IconAngleRight, Notification, IconShare, Link } from 'hds-react';
import ExternalLink from '../../../components/ExternalLink';
import Playground from '../../../components/Playground';
import TabsLayout from './tabs.mdx';
export const _frontmatter = {
  "slug": "/services/website/code",
  "title": "Button - Code"
};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const IconCheckCircleFill = makeShortcode("IconCheckCircleFill");
const layoutProps = {
  _frontmatter
};
const MDXLayout = ({
  children,
  pageContext
}) => <TabsLayout pageContext={pageContext}>{children}</TabsLayout>;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "code",
      "style": {
        "position": "relative"
      }
    }}>{`Code`}<a parentName="h2" {...{
        "href": "#code",
        "aria-label": "code permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h2>
    <h3 {...{
      "id": "code-examples",
      "style": {
        "position": "relative"
      }
    }}>{`Code examples`}<a parentName="h3" {...{
        "href": "#code-examples",
        "aria-label": "code examples permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <h4 {...{
      "id": "primary",
      "style": {
        "position": "relative"
      }
    }}>{`Primary`}<a parentName="h4" {...{
        "href": "#primary",
        "aria-label": "primary permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h4>
    <Playground mdxType="Playground">
      <pre><code parentName="pre" {...{
          "className": "language-jsx"
        }}>{`<Button>Primary</Button>
`}</code></pre>
      <pre><code parentName="pre" {...{
          "className": "language-html"
        }}>{`<button type="button" class="hds-button hds-button--primary">
  <span class="hds-button__label">Primary</span>
</button>
`}</code></pre>
    </Playground>
    <h4 {...{
      "id": "secondary",
      "style": {
        "position": "relative"
      }
    }}>{`Secondary`}<a parentName="h4" {...{
        "href": "#secondary",
        "aria-label": "secondary permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h4>
    <Playground mdxType="Playground">
      <pre><code parentName="pre" {...{
          "className": "language-jsx"
        }}>{`<Button variant="secondary">Secondary</Button>
`}</code></pre>
      <pre><code parentName="pre" {...{
          "className": "language-html"
        }}>{`<button type="button" class="hds-button hds-button--secondary">
  <span class="hds-button__label">Secondary</span>
</button>
`}</code></pre>
    </Playground>
    <h4 {...{
      "id": "supplementary",
      "style": {
        "position": "relative"
      }
    }}>{`Supplementary`}<a parentName="h4" {...{
        "href": "#supplementary",
        "aria-label": "supplementary permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h4>
    <Playground mdxType="Playground">
      <pre><code parentName="pre" {...{
          "className": "language-jsx"
        }}>{`<Button variant="supplementary" iconLeft={<IconTrash />}>
  Supplementary
</Button>
`}</code></pre>
      <pre><code parentName="pre" {...{
          "className": "language-html"
        }}>{`<button type="button" class="hds-button hds-button--supplementary">
  <span aria-hidden="true" class="hds-icon hds-icon--trash"></span>
  <span class="hds-button__label">Supplementary</span>
</button>
`}</code></pre>
    </Playground>
    <h4 {...{
      "id": "icon-button",
      "style": {
        "position": "relative"
      }
    }}>{`Icon button`}<a parentName="h4" {...{
        "href": "#icon-button",
        "aria-label": "icon button permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h4>
    <Playground mdxType="Playground">
      <pre><code parentName="pre" {...{
          "className": "language-jsx"
        }}>{`<div>
  <Button iconLeft={<IconShare />}>Button</Button>
  <Button iconRight={<IconAngleRight />}>Button</Button>
  <Button iconLeft={<IconShare />} iconRight={<IconAngleRight />}>
    Button
  </Button>
</div>
`}</code></pre>
      <pre><code parentName="pre" {...{
          "className": "language-html"
        }}>{`<div>
  <button type="button" class="hds-button hds-button--primary">
    <span aria-hidden="true" class="hds-icon hds-icon--share"></span>
    <span class="hds-button__label">Button</span>
  </button>

  <button type="button" class="hds-button hds-button--primary">
    <span class="hds-button__label">Button</span>
    <span aria-hidden="true" class="hds-icon hds-icon--angle-right"></span>
  </button>

  <button type="button" class="hds-button hds-button--primary">
    <span aria-hidden="true" class="hds-icon hds-icon--share"></span>
    <span class="hds-button__label">Button</span>
    <span aria-hidden="true" class="hds-icon hds-icon--angle-right"></span>
  </button>
</div>
`}</code></pre>
    </Playground>
    <h4 {...{
      "id": "small-button",
      "style": {
        "position": "relative"
      }
    }}>{`Small button`}<a parentName="h4" {...{
        "href": "#small-button",
        "aria-label": "small button permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h4>
    <Playground mdxType="Playground">
      <pre><code parentName="pre" {...{
          "className": "language-jsx"
        }}>{`<div>
  <Button size="small">Primary</Button>
  <Button variant="secondary" size="small">
    Secondary
  </Button>
  <Button variant="supplementary" size="small">
    Supplementary
  </Button>
</div>
`}</code></pre>
      <pre><code parentName="pre" {...{
          "className": "language-html"
        }}>{`<div>
  <button type="button" class="hds-button hds-button--primary hds-button--small">
    <span class="hds-button__label">Primary</span>
  </button>

  <button type="button" class="hds-button hds-button--secondary hds-button--small">
    <span class="hds-button__label">Secondary</span>
  </button>

  <button type="button" class="hds-button hds-button--supplementary hds-button--small">
    <span class="hds-button__label">Supplementary</span>
  </button>
</div>
`}</code></pre>
    </Playground>
    <h4 {...{
      "id": "utility-button",
      "style": {
        "position": "relative"
      }
    }}>{`Utility button`}<a parentName="h4" {...{
        "href": "#utility-button",
        "aria-label": "utility button permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h4>
    <Playground mdxType="Playground">
      <pre><code parentName="pre" {...{
          "className": "language-jsx"
        }}>{`<div>
  <Button variant="success" iconLeft={<IconSaveDisketteFill />}>Save</Button>
  <Button 
    variant="danger" 
    iconLeft={<IconTrash />} 
    style={{ marginLeft: 'var(--spacing-s)' }}
  >
    Delete
  </Button>
</div>
`}</code></pre>
      <pre><code parentName="pre" {...{
          "className": "language-html"
        }}>{`<div>
  <button type="button" class="hds-button hds-button--success">
    <span aria-hidden="true" class="hds-icon hds-icon--save-diskette-fill"></span>
    <span class="hds-button__label">Save</span>
  </button>

  <button type="button" class="hds-button hds-button--danger">
    <span aria-hidden="true" class="hds-icon hds-icon--trash"></span>
    <span class="hds-button__label">Delete</span>
  </button>
</div>
`}</code></pre>
    </Playground>
    <h4 {...{
      "id": "loading-button",
      "style": {
        "position": "relative"
      }
    }}>{`Loading button`}<a parentName="h4" {...{
        "href": "#loading-button",
        "aria-label": "loading button permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h4>
    <Playground mdxType="Playground">
      <pre><code parentName="pre" {...{
          "className": "language-jsx"
        }}>{`{
  () => {
    const [isLoading, setIsLoading] = React.useState(false);
    const [showNotification, setShowNotification] = React.useState(false);
    React.useEffect(() => {
      let timeout;
      if (isLoading) {
        timeout = setTimeout(() => {
          setShowNotification(true);
          setIsLoading(false);
        }, 2000);
      }
      return () => {
        clearTimeout(timeout);
      };
    }, [isLoading]);
    return (
      <>
        <Button
          isLoading={isLoading}
          loadingText="Saving form changes"
          onClick={() => {
            setShowNotification(false);
            setIsLoading(true);
          }}
        >
          Save form
        </Button>
        {showNotification && (
          <Notification
            key={new Date().toString()}
            position="top-right"
            displayAutoCloseProgress={false}
            autoClose
            dismissible
            label="Form saved!"
            type="success"
            onClose={() => {
              setShowNotification(false);
            }}
          >
            Saving your form was successful.
          </Notification>
        )}
      </>
    );
  }
}
`}</code></pre>
    </Playground>
    <h3 {...{
      "id": "packages",
      "style": {
        "position": "relative"
      }
    }}>{`Packages`}<a parentName="h3" {...{
        "href": "#packages",
        "aria-label": "packages permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Package`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Included`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Storybook link`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Source link`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`HDS React`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}><div style={{
              display: 'flex',
              gap: 'var(--spacing-3-xs)'
            }}><IconCheckCircleFill mdxType="IconCheckCircleFill" />{` Yes `}</div></td>
          <td parentName="tr" {...{
            "align": null
          }}><Link openInNewTab openInNewTabAriaLabel="Opens in a new tab" href="/storybook/react/?path=/story/components-button--primary" mdxType="Link">{`View in Storybook`}</Link></td>
          <td parentName="tr" {...{
            "align": null
          }}><ExternalLink openInNewTab openInNewTabAriaLabel="Opens in a new tab" href="https://github.com/City-of-Helsinki/helsinki-design-system/tree/master/packages/react/src/components/button" mdxType="ExternalLink">{`View source`}</ExternalLink></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`HDS Core`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}><div style={{
              display: 'flex',
              gap: 'var(--spacing-3-xs)'
            }}><IconCheckCircleFill mdxType="IconCheckCircleFill" />{` Yes `}</div></td>
          <td parentName="tr" {...{
            "align": null
          }}><Link openInNewTab openInNewTabAriaLabel="Opens in a new tab" href="/storybook/core/?path=/story/components-button--primary" mdxType="Link">{`View in Storybook`}</Link></td>
          <td parentName="tr" {...{
            "align": null
          }}><ExternalLink openInNewTab openInNewTabAriaLabel="Opens in a new tab" href="https://github.com/City-of-Helsinki/helsinki-design-system/tree/master/packages/core/src/components/button" mdxType="ExternalLink">{`View source`}</ExternalLink></td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "properties",
      "style": {
        "position": "relative"
      }
    }}>{`Properties`}<a parentName="h3" {...{
        "href": "#properties",
        "aria-label": "properties permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <p>{`Note! You can find the full list of properties in the `}<Link openInNewTab openInNewTabAriaLabel="Opens in a new tab" href="/storybook/react/?path=/docs/components-button--primary" mdxType="Link">{`React Storybook.`}</Link></p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Property`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Values`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Default value`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`variant`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Variant of the button.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`"primary"`}</inlineCode>{` `}<inlineCode parentName="td">{`"secondary"`}</inlineCode>{` `}<inlineCode parentName="td">{`"supplementary"`}</inlineCode>{` `}<inlineCode parentName="td">{`"success"`}</inlineCode>{` `}<inlineCode parentName="td">{`"danger"`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`"primary"`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`size`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Size of the button.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`"default"`}</inlineCode>{` `}<inlineCode parentName="td">{`"small"`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`"default"`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`iconLeft`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Icon placed on the left side of the button label.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`ReactNode`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`iconRight`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Icon placed on the right side of the button label.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`ReactNode`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`fullWidth`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`If set to true, the button will take full width of its container.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`boolean`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`false`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`isLoading`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`If set to true, a loading spinner is displayed inside the button.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`boolean`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`false`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`loadingText`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Visible loading text to be shown next to the loading spinner.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`string`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      